var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-sm mb-3"},[_c('h2',{staticClass:"h4 mb-4 text-center font-weight-bold"}),(_vm.networks.length)?_c('div',{staticClass:"row mb-4"},_vm._l((_vm.networks),function(network,index){return _c('div',{key:index,staticClass:"col-6 col-md-4 col-lg-3 mb-3"},[_c('router-link',{staticClass:"card text-reset h-100",attrs:{"to":{
          name: 'Network',
          params: {
            code: network.ProviderCode.toLowerCase(),
            name: network.Name.replace(/\s/g, '-').toLowerCase(),
            iso: network.CountryIso.toLowerCase(),
            logo: network.LogoUrl
          }
        },"role":"button"}},[_c('div',{staticClass:"card-img img-thumbnail border-0 text-center py-4"},[(network.LogoUrl)?_c('img',{staticClass:"w-auto h-auto",staticStyle:{"max-width":"75%","max-height":"30px"},attrs:{"src":network.LogoUrl.replace('png', 'svg'),"onerror":'this.src=' + network.LogoUrl}}):_vm._e()]),_c('div',{staticClass:"card-footer h-100"},[_vm._v(" "+_vm._s(network.Name)+" ")])])],1)}),0):_c('p',{staticClass:"text-muted"},[_vm._v("No Networks Found")])])}
var staticRenderFns = []

export { render, staticRenderFns }