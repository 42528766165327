<template>
  <div class="container-sm mb-3">
    <h2 class="h4 mb-4 text-center font-weight-bold"></h2>
    <div class="row mb-4" v-if="networks.length">
      <div
        class="col-6 col-md-4 col-lg-3 mb-3"
        v-for="(network, index) in networks"
        :key="index"
      >
        <router-link
          :to="{
            name: 'Network',
            params: {
              code: network.ProviderCode.toLowerCase(),
              name: network.Name.replace(/\s/g, '-').toLowerCase(),
              iso: network.CountryIso.toLowerCase(),
              logo: network.LogoUrl
            }
          }"
          class="card text-reset h-100"
          role="button"
        >
          <div class="card-img img-thumbnail border-0 text-center py-4">
            <img
              :src="network.LogoUrl.replace('png', 'svg')"
              :onerror="'this.src=' + network.LogoUrl"
              class="w-auto h-auto"
              style="max-width:75%;max-height:30px"
              v-if="network.LogoUrl"
            />
          </div>
          <div class="card-footer h-100">
            {{ network.Name }}
          </div>
        </router-link>
      </div>
    </div>
    <p class="text-muted" v-else>No Networks Found</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      networks: []
    };
  },
  computed: {
    ...mapGetters({
      country: "country"
    })
  },
  beforeMount() {
    this.fetchNetworks(this.$route.params.country);
  },
  head: {
    title() {
      return {
        inner: 'Mobile Top-ups Online | ' +this.$route.params.country.charAt(0).toUpperCase() + this.$route.params.country.slice(1) + ' | Choose a network',
        separator: '|',
        complement: 'Topups.Com'
      }
    },
    meta() {
      return [
        { n: 'description', c: 'Choose a mobile network then top-up using our speedy phone recharge service from the comfort of your home or on holiday overseas.', id: 'desc' }, // id to replace intead of create element
        // Twitter
        { n: 'twitter:title', c: 'Mobile Top-ups Online | ' +this.$route.params.country.charAt(0).toUpperCase() + this.$route.params.country.slice(1) + ' | Choose a network | Topups.Com' },
        { n: 'twitter:description', c: 'Choose a mobile network then top-up using our speedy phone recharge service from the comfort of your home or on holiday overseas.'},
        // Google+ / Schema.org
        { ip: 'name', c: 'Mobile Top-ups Online | ' +this.$route.params.country.charAt(0).toUpperCase() + this.$route.params.country.slice(1) + ' | Choose a network | Topups.Com' },
        { ip: 'description', c: 'Choose a mobile network then top-up using our speedy phone recharge service from the comfort of your home or on holiday overseas.' },
        // Facebook / Open Graph
        // { p: 'fb:app_id', c: '123456789' },
        { p: 'og:title', c: 'Mobile Top-ups Online | ' +this.$route.params.country.charAt(0).toUpperCase() + this.$route.params.country.slice(1) + ' | Choose a network | Topups.Com' },
        // { p: 'og:image', c: 'https://example.com/image.jpg' },
      ]
    },
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  },
  methods: {
    fetchNetworks(value) {
      this.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}networks?countryName=${value}`
      }).then(
        result => {
          this.networks = result.data.networks;
        },
        error => {
          console.error(error);
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-img {
  max-height: 100px;
  object-fit: contain;
}
.details {
  left: 0;
  bottom: 0;
  opacity: 1;
  background-color: rgba(#000000, 0.5);
  transition: all 0.2s ease-in-out;
  > div {
    transform: translateY(0);
    border-radius: 1rem 1rem 0 0;
    transition: transform 0.2s ease-in-out;
  }
  &:not(.show) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    > div {
      transform: translateY(100%);
    }
  }
}
</style>
